.active_title{
    color: rgb(92 43 130 / var(--tw-border-opacity));
    font-weight: bolder;
}

.banner_text::-webkit-scrollbar{
    background:white;
    width: 0.5rem;
    border-radius: 0.5rem;
}
.banner_text::-webkit-scrollbar-thumb{
    background-color: rgb(92 43 130 );
    border-radius: 0.5rem;
}